import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  marginTop,
  Color,
  typographyByBreakpoint,
  h3Desktop,
  maxWidth,
  marginLeft,
  columnSpacing,
  Width,
  xxlMobile,
  xxlDesktop,
  hover,
  flex,
  position,
  width,
  height,
  owlSelector,
  marginTopBottom,
} from '../../../../styles';
import {UpcomingCard} from './UpcomingCard';

const Wrapper = styled.section`
  ${marginTopBottom([136, 80], [152, 96], [206, 120])};
  ${maxWidth('32.1rem', '68.8rem', '144rem')};
  margin-inline: auto;
`;

const EventWrapper = styled.div`
  ${columnSpacing(0, 48, 159)};
  //added z-index and position relative for EventWrapper & Card to make Vector component not overlapping divs
  position: relative;
  z-index: 1;
  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    ${flex('flex-start', 'center')};
  }
`;

const CardSection = styled.div`
  margin-inline: auto;
  ${maxWidth('32.1rem', '57rem', '120rem')};
  ${marginTop(48, 48, 64)};
  ${owlSelector('top', 32, 32, 48)};
`;

const Header = styled.h1`
  ${typographyByBreakpoint(xxlMobile, xxlMobile, xxlDesktop)};
  color: ${Color.SEAFOAM_GREEN};
`;

const Tab = styled.div`
  ${marginTop(32, 0)};
  ${flex('flex-start')};
  column-gap: 3.2rem;
`;

const TabLink = styled.a<{ currentActive: boolean }>`
  ${h3Desktop};
  cursor: pointer;
  color: ${Color.BLACK};
  padding-bottom: 0.4rem;
  ${({ currentActive }) =>
    currentActive
      ? css`
          border-bottom: 3px solid ${Color.BLACK};
        `
      : css`
          ${hover('opacity', 0.55, 1)};
          border-bottom: 3px solid transparent;
        `}
`;

const Vector = styled.div`
  z-index: -1;
  position: absolute;
  ${position('top', -148, -278, -214)};
  ${position('right', -152, -159, -206)};
  ${width('39.1rem', '61rem', '88rem')};
  ${height('39.1rem', '61rem', '88rem')};
  border-radius: 50%;
  background: linear-gradient(136.25deg, rgba(130, 138, 188, 0) 14.98%, rgb(198 200 212 / 36%) 93.71%);
  opacity: 0.5;
  transform: matrix(0.97, -0.26, -0.26, -0.97, 0, 0);
  overflow: hidden;
`;

const Content = styled.div`
  ${marginLeft(0, 0, 107)};
  ${maxWidth('32.7rem', '57rem', '105rem')};
  background-color: ${Color.WHITE};
  box-shadow: 33.7444px 33.7444px 60.7399px rgba(166, 180, 200, 0.1), 0 14.1616px 14.1616px rgba(0, 0, 0, 0.08);
  text-align: center;
  padding: 10.4rem 0;
  margin-top: 3.8rem;

  p {
    margin-top: 1.6rem;
  }
  
  h2 {
    ${h3Desktop}
  }
  
  }
`;

const NoEvent = () => {
  return (
    <Content>
      <h2>No upcoming events</h2>
      <p>More events coming soon</p>
    </Content>
  );
};

const UpcomingEvents = ( {data}) => {
  const allUpcomingEvents = data && data.upcomingEvents && mapEdgesToNodes(data.upcomingEvents)

  const upcomingEvent =
    allUpcomingEvents?.length === 0 ? <NoEvent /> : 
      allUpcomingEvents?.map((event, index) => 
        <UpcomingCard key={index} {...event} />);

  const [active, setActive] = useState(true);
  
  return (
    <Wrapper>
      <EventWrapper>
        <Vector />
        <Header>Events</Header>
        <Tab>
          <TabLink currentActive={active}>
            Upcoming
          </TabLink>
          <TabLink 
            currentActive={!active}
            href="/community/pastevents">
            Past
          </TabLink>
        </Tab>
      </EventWrapper>
      <CardSection>{upcomingEvent}</CardSection>
    </Wrapper>
  );
};

function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export default UpcomingEvents;
