import React from 'react';
import {graphql} from "gatsby";
import WebsiteDefaultPage from '../../components/Page/Base';
import { UpcomingEvents } from '../../components/PageSpecific/community/events';
import { JoinSection } from '../../components/SharedSections';
import { Color } from '../../styles';
import { LIGHT_DARK } from '../../constants';

const Events = ({data}) => {
  return (
    <WebsiteDefaultPage bodyBackground={Color.OFF_WHITE} pageState={{ theme: LIGHT_DARK.LIGHT }}>
      <UpcomingEvents data = {data}/>
      <JoinSection />
    </WebsiteDefaultPage>
  );
};

export default Events;

export const query = graphql`
query EventsQuery {
 pastEvents: allSanityPastEvents(sort: {fields: eventStartDate, order: DESC}) {
    edges {
      node {
        id
        eventStartDate
        date
        day
        title
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(fit: FILL, placeholder: BLURRED)
            url
          }
        }
      }
    }
  }
  
  upcomingEvents: allSanityUpcomingEvents(sort: {fields: eventStartDate, order: DESC}) {
      edges {
        node {
          id
          eventStartDate
          date
          day
          eventLink
          title
          desc
          mainImage {
            asset {
              gatsbyImageData(fit: FILL, placeholder: BLURRED)
              url
            }
          }
        }
      }
    }
  }
`