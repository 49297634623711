import React from 'react';
import styled from 'styled-components';
import {
  marginTop,
  bodyBold,
  gridColumns,
  rowSpacing,
  Color,
  typographyByBreakpoint,
  h1Mobile,
  h1Desktop,
  h2Mobile,
  h2Desktop,
  h3Desktop,
  paddingTop,
  paddingBottom,
  marginLeft,
  columnSpacing,
  Width,
} from '../../../../styles';
import { Button } from '../../../Gui/Button';

const Card = styled.div`
  ${marginLeft(0, 0, 107)};
  position: relative;
  z-index: 1;

  display: grid;
  ${gridColumns(1, 1, 'unset')}
  ${rowSpacing('unset', 'unset', 96)};
  ${columnSpacing('unset', 'unset', 95)};
  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    grid-template-columns: 40.6rem 62rem;
  }
`;

const CardHeader = styled.div`
  border-bottom: 4px solid ${Color.GREEN};

  @media screen and (min-width: ${Width.BREAK_XL}px) {
    border-right: 4px solid ${Color.GREEN};
    border-bottom: none;
  }
`;

const EventDays = styled.p`
  ${paddingTop(0, 0, 32)};
  color: ${Color.GRAY};
  font: ${bodyBold};
`;

const EventDate = styled.h2`
  ${typographyByBreakpoint(h1Mobile, h1Mobile, h1Desktop)};
  ${paddingBottom(8, 16)}
`;

const CardContainer = styled.div`
  .button-wrapper {
    ${marginTop(24, 32, 48)};
  }
`;

const Location = styled.p`
  ${marginTop(16, 16, 24)};
  ${typographyByBreakpoint(bodyBold, bodyBold, h3Desktop)};
`;

const EventTitle = styled.h3`
  ${typographyByBreakpoint(h2Mobile, h2Mobile, h2Desktop)};
  ${paddingTop(16, 24)}
`;

const EventDesc = styled.p`
  ${marginTop(0, 0, 8)};
`;

export const UpcomingCard = ({
    day,
    date,
    desc,
    title,
    location,
    mainImage,
    eventLink,
  }: {
    day?: string;
    desc: string;
    body: string;
    date: any;
    title: string;
    location?: string;
    eventLink?: string;
    mainImage: any;
  }) => (
    <Card> 
      <CardHeader>
        <EventDays>{day}</EventDays>
        <EventDate>{date}</EventDate>
      </CardHeader>
      <CardContainer>
        <EventTitle>{title}</EventTitle>
        <Location>{location}</Location>
        <EventDesc>{!desc ? <><a href={mainImage.asset.url}>Event Information</a></> : <p>{desc}</p>}</EventDesc>
        {eventLink ? <Button url={eventLink}>Register Now</Button> : null}
      </CardContainer>
    </Card>
        )